@import "./mixins";
@import "./variables";
@import "./fonts";

.tag- {
  &button {
    text-transform: uppercase;
    @include label_bold;
    cursor: pointer;

    &__primary {
      border-radius: 100px;
      @include center(row);
      // &--active {
      //   color: $black;
      // }

      &--large {
        padding: 21px 40px;
      }
      &--medium {
        padding: 15px 36px;
      }

      &--orange {
        background-color: $main;
        border: 1px solid $main;
      }

      &--black {
        background-color: $black;
        border: 1px solid $black;
      }

      &--white {
        background-color: $white;
        border: 1px solid $white;
      }

      &--orange,
      &--black {
        color: $white;
      }

      &--disabled.tag-button__primary--orange,
      &--disabled.tag-button__primary--black {
        pointer-events: none;
        color: $white;
        background-color: $disabled;
        border: 1px solid $disabled;
      }

      &--disabled.tag-button__primary--white {
        pointer-events: none;
        color: $disabled;
      }

      &--disable &--orange {
        background-color: $black;
      }
    }
  }
  // button__primary button__primary--active button__primary--large orange">
}
