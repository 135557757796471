@mixin flex($direction: row) {
  display: flex;
  flex-direction: $direction;
}

@mixin center($direction: row) {
  @include flex($direction);
  justify-content: center;
  align-items: center;
}

@mixin margin($margin_values: auto) {
  margin: $margin_values;
}

@mixin color($color) {
  color: $color;
}

@mixin bg-color($color) {
  background-color: $color;
}

@mixin secondaryButton() {
  @include link_small;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  align-items: center;
  padding: 12px 14px;
  // width: 50px;
  // height: 18px;
  // left: calc(50% - 85px / 2 - 467px);
  // top: calc(50% - 60px / 2 - 405.5px);

  border: 2px solid $grey_light;
  box-sizing: border-box;
  border-radius: 100px;
  // font-family: Neue Haas Grotesk Display Pro, sans-serif;
  // font-style: normal;
  // font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  /* identical to box height, or 129% */
  letter-spacing: 0.03em;
  background-color: Transparent;
  background-repeat: no-repeat;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}
