@import "./styles/variables";
@import "./styles/buttons";
@import "./styles/forms";
@import "./styles/mixins";
@import "./styles/media-queries";
@import "./styles/fonts";
@import "./styles/generic";
@import url("https://cdn.talentgarden.com/libraries/fontello/css/fontello.css");

body {
  height: $full_height;
}

.container {
  background-color: $white;
  @include center(column);
  margin: 0 auto;
  color: $black;
  width: $full_width / 2;
  @include xs {
    width: $full_width - 7vw;
  }
}

// h1 {
//   color: lightblue;
// }

// @use "styles/variables";

// @mixin center {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// body {
//   background-color: green /* variables.$white */;
// }

// .App {
//   // text-align: center;
//   @include center;
//   height: 100vh;

//   .container {
//     background-color: red;

//     h1 {
//       color: #fff;
//     }
//   }
// }

.tag-c- {
  &white {
    @include color($white);
  }

  &black {
    @include color($black);
  }

  &orange {
    @include color($main);
  }

  &orange_light {
    @include color($main_light);
  }

  &peach {
    @include color($main_peach);
  }

  &burgundy {
    @include color($secondary_burgundy);
  }
  &yellow {
    @include color($secondary_yellow);
  }
  &grey {
    @include color($grey);
  }
  &grey_light {
    @include color($grey_light);
  }
  &grey_super_light {
    @include color($grey_super_light);
  }

  &success {
    @include color($success);
  }

  &success_opacity20 {
    @include color($success_opacity20);
  }

  &error {
    @include color($error);
  }

  &error_opacity20 {
    @include color($error_opacity20);
  }
}

.tag-bg- {
  &white {
    @include bg-color($white);
  }

  &black {
    @include bg-color($black);
  }

  &orange {
    @include bg-color($main);
  }

  &orange_light {
    @include bg-color($main_light);
  }

  &peach {
    @include bg-color($main_peach);
  }

  &burgundy {
    @include bg-color($secondary_burgundy);
  }
  &yellow {
    @include bg-color($secondary_yellow);
  }
  &grey {
    @include bg-color($grey);
  }
  &grey_light {
    @include bg-color($grey_light);
  }
  &grey_super_light {
    @include bg-color($grey_super_light);
  }

  &success {
    @include bg-color($success);
  }

  &success_opacity20 {
    @include bg-color($success_opacity20);
  }

  &error {
    @include bg-color($error);
  }

  &error_opacity20 {
    @include bg-color($error_opacity20);
  }
}
