// colors
$white: #ffffff;
$black: #000000;
$main: #ec7660;
$main_light: #fec4b5;
$main_peach: #fae5d5;
$secondary_burgundy: #723349;
$secondary_yellow: #ffd670;
$black: #1c1e1e;
$grey: #858788;
$grey_light: #e6e7e8;
$grey_super_light: #f5f5f5;
$disabled: #d2d2d2;
$success: rgba(115, 209, 125, 1);
$success_opacity20: rgba(115, 209, 125, 0.2);
$error: rgba(225, 97, 97, 1);
$error_opacity20: rgba(225, 97, 97, 0.2);
// dimensions
$full_height: 100vh;
$full_width: 100vw;
$width_mobile: 95vw;

// measures
$tiny: 8px;
$small: 12px;
$regular: 16px;
$large: 26px;
$xlarge: 36px;
