.footer {
  background: #333;
  color: white;

  .details {
    padding: 60px 80px;
    display: grid;
    grid-template-columns: 25% 1fr 1fr;

    div {
      align-self: center;
    }
  }

  .disclaimer {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding: 30px 80px;
    font-size: 70%;
  }

  .link {
    font-weight: 700;
  }

  .social-share ul {
    list-style: none;
  }

  .social-share ul li {
    display: inline-block;
    padding: 0 10px;
  }


.social-icon {
  color: white;
  filter: invert(255%) sepia(100%) saturate(6481%) hue-rotate(246deg) brightness(255%) contrast(143%); 
  width: 25px;
  height: 25px;
}

}

.logo-link:hover,
.link:hover,
.footer a:hover {
  opacity: 0.8;
  color: white !important;
  text-decoration: none !important;
}

.link,
.link:visited {
  color: white;
  text-decoration: none !important;
}

@media screen and (max-width: 859px) {
  .footer {
    .details {
      padding: 60px 40px;
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
    }

    .disclaimer {
      padding: 30px 40px;
    }

    .social-share {
      text-align: center;
    }
    .social-share ul {
      padding-inline-start: 0px;
    }
  }
}

