@import "./mixins";
@import "./variables";

.tag- {
  &margin-auto {
    @include margin(auto);
  }

  &pb-tiny {
    padding-bottom: $tiny;
  }

  &pb-small {
    padding-bottom: $small;
  }

  &pb-regular {
    padding-bottom: $regular;
  }

  &pb-large {
    padding-bottom: $large;
  }

  &pb-xlarge {
    padding-bottom: $xlarge;
  }

  &pt-tiny {
    padding-top: $tiny;
  }

  &pt-small {
    padding-top: $small;
  }

  &pt-regular {
    padding-top: $regular;
  }

  &pt-large {
    padding-top: $large;
  }

  &pt-xlarge {
    padding-top: $xlarge;
  }

  &mb-tiny {
    margin-bottom: $tiny;
  }

  &mb-small {
    margin-bottom: $small;
  }

  &mb-regular {
    margin-bottom: $regular;
  }

  &mb-large {
    margin-bottom: $large;
  }

  &mb-xlarge {
    margin-bottom: $xlarge;
  }

  &mt-tiny {
    margin-top: $tiny;
  }

  &mt-small {
    margin-top: $small;
  }

  &mt-regular {
    margin-top: $regular;
  }

  &mt-large {
    margin-top: $large;
  }

  &mt-xlarge {
    margin-top: $xlarge;
  }
}
