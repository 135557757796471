@import "./variables";
@font-face {
  font-family: "Lubalin";
  src: url("https://cdn.talentgarden.com/fonts/lubalin/lubalingraphstd-demi-webfont.ttf");
}

@font-face {
  font-family: "NHaasGroteskDSPro-45Lt";
  src: url("https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-45Lt.ttf");
}

@font-face {
  font-family: "NHaasGroteskDSPro-46LtIt";
  src: url("https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-46LtIt.ttf");
}

@font-face {
  font-family: "NHaasGroteskDSPro-55Rg";
  src: url("https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-55Rg.ttf");
}

@font-face {
  font-family: "NHaasGroteskDSPro-56It";
  src: url("https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-56It.ttf");
}

@font-face {
  font-family: "NHaasGroteskDSPro-65Md";
  src: url("https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-65Md.ttf");
}

@font-face {
  font-family: "NHaasGroteskDSPro-66MdIt";
  src: url("https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-66MdIt.ttf");
}

@mixin body_regular {
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
}

@mixin body_bold {
  font-family: "NHaasGroteskDSPro-65Md", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
}

@mixin input {
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
}

@mixin link_small {
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
  //styleName: Link/Small;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.04em;
}

@mixin link_regular {
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: $main;
  text-decoration: none;
}

@mixin label_small {
  font-family: "NHaasGroteskDSPro-65Md", sans-serif;
  //styleName: Label/small;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.02em;
}

@mixin label_regular {
  font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
  //styleName: Label/regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
}

@mixin label_bold {
  font-family: "NHaasGroteskDSPro-65Md", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
}

.tag {
  &-h1 {
    font-family: "NHaasGroteskDSPro-65Md", sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.01em;

    &.lubalin {
      font-family: "Lubalin", serif;
    }
  }

  &-h2 {
    font-family: "NHaasGroteskDSPro-65Md", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.01em;

    &.lubalin {
      font-family: "Lubalin", serif;
    }
  }

  &-h3 {
    font-family: "NHaasGroteskDSPro-65Md", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.01em;
  }

  &-h4 {
    font-family: "NHaasGroteskDSPro-65Md", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  &-h5 {
    font-family: "NHaasGroteskDSPro-65Md", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  &-body_regular {
    @include body_regular;
  }

  &-body_bold {
    @include body_bold;
  }

  &-input {
    @include input;
  }

  &-link_small {
    @include link_small;
  }

  &-link_regular {
    @include link_regular;
  }

  &-label_small {
    @include label_small;
  }

  &-label_regular {
    @include label_regular;
  }

  &-label_bold {
    @include label_bold;
  }

  &-center_text {
    text-align: center;
  }

  &-left_text {
    text-align: left;
  }

  &-right_text {
    text-align: right;
  }
}
