@import "./mixins";
@import "./media-queries";
@import "./variables";

// form {
option,
optgroup {
  -webkit-appearance: none;
}

option,
optgroup {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
  outline: none !important;
}

input,
select,
textarea {
  outline: none;
}

.form {
}
.tag- {
  &input__container {
    background-color: $white;
    position: relative;
    @include flex(column);

    .input__subtext {
      padding-bottom: 8px;
      color: $grey;
      font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.02em;
    }

    .input__label {
      color: $grey;
      padding-bottom: 8px;
      @include label_regular;

      &--required::after {
        margin-left: 4px;
        color: red;
        content: "*";
      }
    }

    .input__form {
      border-radius: 4px;
      padding: 15px 14px;
      border: 1px solid $grey_light;
      color: $black;
      @include input;
      appearance: none;

      &::placeholder {
        color: $grey;
      }

      &:focus {
        border-color: $black;
        color: $black;
      }
      &:disabled {
        background-color: $grey_super_light;
        color: $grey;
      }
    }

    .select__form { 
      background-color: transparent;
      border: 1px solid $grey_light;
      overflow: hidden;
      height: 50px;
      border-radius: 4px;
      padding: 0.25em 0.5em;
      line-height: 1.1;
      width: 100%;
      font-family: "NHaasGroteskDSPro-55Rg", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: $black;

      &[data-open="true"] {
        box-shadow: 0px 4px 20px 0px #0f0f0f1f;
        border: 1px solid $black;
        // border-bottom: 0;
      }

      // &__open {
      //   box-shadow: 0px 4px 20px 0px #0F0F0F1F;
      //   border: 1px solid $black;
      // }
      .option__default {
        padding: 15px 14px;
        color: $black;
        display: flex;
        align-items: "center";
        justify-content: space-between;
        // cursor: "pointer";
        cursor: pointer;
        @include input;

        &[data-open="true"] {
          // box-shadow: 0px 4px 20px 0px #0f0f0f1f;
          // border: 1px solid $black;
          border-bottom: 0;
        }

        // &__open p,
        // &__not_selected p {
        //   color: $grey;
        // }
      }

      .option__list {
        padding: 0px 10px;
        position: absolute;
        background-color: $white;
        right: 0;
        left: 0;
        z-index: 99;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        max-height: 240px;
        overflow: scroll;

        &[data-open="true"] {
          box-shadow: 0px 20px 20px 0px #0f0f0f1f;
          border: 1px solid $black;
          border-top: 1px solid $grey_light;
        }
      }
      .option__select {
        padding: 16px 10px;
        border-bottom: 1px solid $grey_light;
        color: $black;
        width:100%;
        cursor: pointer;

        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }

      &__open p,
      &__not_selected p {
        color: $grey;
      }
      p {
        background-color: $white;
        margin: 0px;
      }

      &::placeholder {
        color: $grey;
      }

      &:focus {
        border-color: $black;
        color: $black;
      }
      option {
        background-color: $white;
        padding: 10px;
      }
    }
    // todo style file button
    // https://stackoverflow.com/questions/572768/styling-an-input-type-file-button
    input[type="file"] {
      color: $grey;
      padding-bottom: 8px;
      @include label_regular;
    }

    input[type="file"]::file-selector-button {
      @include secondaryButton;
      color: $main;
    }
  }
}
// }
